import React from "react";
import styled from "styled-components";

const DonationSectionContainer = styled.div`
	text-align: center;
	color: #212529;
	padding: 50px 0 100px 0;
	margin: 50px 0 0 0;
	background-color: #f4f4f4;
`;

const AnchorSpacer = styled.div`
	position: relative;
	top: -80px;
	left: 0;
	height: 1px;
	width: 1px;
	margin: 0px 0 50px; /* margin bottom pushes container into view after link click page scroll */
`;

const Title = styled.h3`
	font-size: 40px;
	margin: 0 0 5px 0;

	@media screen and (max-width: 760px) {
		font-size: 30px;
	}
`;

const Subtitle = styled.p`
	font-size: 20px;
	margin: 0 4% 25px;
`;

const DonationCardsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	/* margin: 0 4% 0; */
	gap: 20px;
	@media screen and (max-width: 960px) {
		flex-direction: column;
		align-items: center;
	}
`;

const DonationCard = styled.div`
	color: #004aad;
	display: flex;
	justify-content: center;
	width: 350px;
	padding: 30px 60px;
	background-color: #c1ff72;
	border-radius: 10px;
	box-shadow: 0 0 10px #18181b;
	gap: 200px;
	@media screen and (max-width: 960px) {
		padding: 10px 0;
		gap: 20px;
		flex-direction: column;
	}
`;

const GiveSendGoImage = styled.img`
	height: 45px;
	transition: 0.3s;
	@media screen and (max-width: 960px) {
		height: 40px;
	}
	&:hover {
		cursor: pointer;
		transform: scale(120%);
	}
`;
const PayPalImage = styled.img`
	height: 50px;
	transition: 0.3s;
	&:hover {
		cursor: pointer;
		transform: scale(120%);
	}
`;

const DonationSection = () => {
	return (
		<DonationSectionContainer>
			<AnchorSpacer id="donate"></AnchorSpacer>
			<Title>Help Us Make a Difference</Title>
			<Subtitle> by donating to our charitable courses using any of the options below</Subtitle>
			<DonationCardsContainer>
				<DonationCard>
					<a href="https://www.givesendgo.com/GCZ18" target="_blank" rel="noreferrer">
						<GiveSendGoImage src="./images/givesendgo_logo.png" />
					</a>
				</DonationCard>

				<DonationCard>
					<a href="https://shorturl.at/sxJot" target="_blank" rel="noreferrer">
						<PayPalImage src="./images/paypal_icon.svg" />
					</a>
				</DonationCard>
			</DonationCardsContainer>
		</DonationSectionContainer>
	);
};

export default DonationSection;
