import styled from "styled-components";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import "react-photo-view/dist/react-photo-view.css";

import PrivacyPolicy from "./pages/PrivacyPolicy";
import LandingPage from "./pages/LandingPage";

const AppContainer = styled.div`
	padding: 0;
	margin: 0 auto;
	box-sizing: border-box;
`;

function App() {
	return (
		<AppContainer>
			<Routes>
				<Route path="/" element={<LandingPage />} />
				<Route path="/privacy-policy" element={<PrivacyPolicy />} />
			</Routes>
		</AppContainer>
	);
}

export default App;
