import NavbarSection from "../components/Navbar/NavbarSection";
import HeroSection from "../components/Hero/HeroSection";
import AboutCharity from "../components/MainBody/AboutCharity";
import YouTubeVideo from "../components/MainBody/YouTubeVideo";
import Gallery from "../components/MainBody/Gallery";
import DonationSection from "../components/MainBody/DonationSection";
import FooterSection from "../components/Footer/FooterSection";

const LandingPage = () => {
	return (
		<>
			<NavbarSection />
			<HeroSection />
			<YouTubeVideo embedId="foGamN3H3I0" />
			<Gallery />
			<AboutCharity />
			<DonationSection />
			<FooterSection />
		</>
	);
};

export default LandingPage;
