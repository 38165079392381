import React from "react";
import styled from "styled-components";

const LogoitemContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	@media screen and (max-width: 500px) {
		margin-left: 0;
	}
`;

const LogoImage = styled.img`
	width: 60px;
	margin: 0 5px 0 0;
	@media screen and (max-width: 500px) {
		width: 30px;
	}
`;

const LogoText = styled.span`
	color: #004aad;
	font-size: 22px;
	font-weight: 500;
	@media screen and (max-width: 500px) {
		display: none;
	}
`;
const LogoTextMobile = styled.span`
	color: #004aad;
	font-family: serif;
	display: none;
	@media screen and (max-width: 500px) {
		font-size: 16px;
		font-weight: 700;
		display: block;
	}
`;

const LogoItem = () => {
	return (
		<LogoitemContainer>
			<LogoImage src="./images/logo_round.png" alt="" />
			<LogoText>Beneficial Projects</LogoText>
			<LogoTextMobile>Beneficial Projects</LogoTextMobile>
		</LogoitemContainer>
	);
};

export default LogoItem;
