import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Offcanvas from "react-bootstrap/Offcanvas";

import LogoItem from "./LogoItem";

function NavbarSectionBS() {
	const expand = "lg"; // Set the desired screen breakpoint here
	const [showOffcanvas, setShowOffcanvas] = useState(false);

	const handleNavLinkClick = () => {
		setShowOffcanvas(false);
	};

	return (
		<>
			<Navbar expand={expand} className="fixed-top bg-body-tertiary py-1 navbar-custom">
				<Container fluid style={{ padding: 0 }}>
					<Navbar.Brand href="/">
						<LogoItem />
					</Navbar.Brand>

					<Navbar.Toggle
						aria-controls={`offcanvasNavbar-expand-${expand}`}
						className="burger-button"
						onClick={() => setShowOffcanvas((prev) => !prev)}
					/>

					<Navbar.Offcanvas
						show={showOffcanvas}
						onHide={() => setShowOffcanvas(false)}
						id={`offcanvasNavbar-expand-${expand}`}
						aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
						placement="end"
					>
						<Offcanvas.Header closeButton closeVariant="" className="side-menu">
							<Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
								{/* Menu Title Text */}
							</Offcanvas.Title>
						</Offcanvas.Header>
						<Offcanvas.Body className="side-menu">
							<Nav className="justify-content-end flex-grow-1">
								<Nav.Link
									href="#about-charity"
									className="mx-4 text-center navbar-link"
									onClick={handleNavLinkClick}
								>
									About Charity
								</Nav.Link>

								<Nav.Link
									href="#donate"
									className="mx-4 text-center navbar-link"
									onClick={handleNavLinkClick}
								>
									Donate
								</Nav.Link>

								{/* <Nav.Link
									href="#team"
									className="mx-4 text-center navbar-link"
									onClick={handleNavLinkClick}
								>
									Link #3
								</Nav.Link> */}

								{/* <a
									className="mx-4 my-auto text-center navbar-link"
									href="/charity"
									target="_blank"
									rel="noopener noreferrer"
								>
									Charity
								</a> */}

								{/* <Nav.Link
									className="contact-button"
									href="#contact"
									onClick={handleNavLinkClick}
								>
									Contact Us
								</Nav.Link> */}

								<div
									className="contact-button"
									href="#contact"
									onClick={() =>
										(window.location.href = "mailto:beneficialproject@yahoo.com")
									}
								>
									Contact Us
								</div>
							</Nav>
						</Offcanvas.Body>
					</Navbar.Offcanvas>
				</Container>
			</Navbar>
		</>
	);
}

export default NavbarSectionBS;
