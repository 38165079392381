import styled from "styled-components";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { Gallerydata } from "../../data/GalleryData";

const GalleryContainer = styled.div`
	padding: 10px 0;
	padding: 50px 0 0 0;
	/* background-color: #c1ff72; */
	background: linear-gradient(to bottom, #c1ff72, #ffffff);
`;

const GalleryItems = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin: 0 8% 0;
	gap: 10px;
	@media screen and (max-width: 960px) {
		flex-direction: column;
	}
`;

const Image = styled.img`
	height: 150px;
	border-radius: 5px;
	transition: 0.3s;
	cursor: pointer;
	&:hover {
		box-shadow: 0 0 10px #18181b;
	}
	@media screen and (max-width: 960px) {
		height: auto;
		width: 100%;
		border-radius: 5px;
	}
`;

const Gallery = () => {
	return (
		<GalleryContainer>
			<PhotoProvider>
				<GalleryItems>
					{Gallerydata.map((Gallerydata) => (
						<PhotoView key={Gallerydata.key} src={Gallerydata.url}>
							<Image src={Gallerydata.url} alt="" />
						</PhotoView>
					))}
				</GalleryItems>
			</PhotoProvider>
		</GalleryContainer>
	);
};

export default Gallery;
