import React from "react";
import styled from "styled-components";

const FooterSectionContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	color: #fdd803;
	background-color: #004aad;
	padding: 30px 0;
`;

const ColumnContainer = styled.div`
	display: flex;
	justify-content: space-around;
	width: 90%;
	gap: 50px;
	margin: 0 auto;
	@media screen and (max-width: 760px) {
		flex-direction: column;
		gap: 10px;
		width: 85%;
	}
`;

const Column1 = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	height: fit-content;
	@media screen and (max-width: 760px) {
		flex-direction: column;
		justify-content: space-between;
		gap: 0;
	}
`;

const SocialsRow = styled.div`
	display: flex;
	flex-direction: row;
	gap: 20px;
	margin: 0 0 0 10px;
	@media screen and (max-width: 760px) {
		flex-direction: column;
		margin: 10px 0 0 0;
		gap: 10px;
	}
`;

const SocialIconContainer = styled.div`
	display: flex;
	align-items: center;
`;

const SocialIcons = styled.img`
	width: 20px;
`;

const LogoRow = styled.div`
	display: flex;
	flex-direction: row;
`;

const Logo = styled.img`
	width: 45px;
	/* height: 30px; */
	margin: 0 10px 0 0;
	@media screen and (max-width: 760px) {
		height: 20px;
		width: 20px;
	}
`;

const BusinessName = styled.p`
	margin: 0 0 0 0;
	align-self: center;
`;

const Column2 = styled.div`
	display: flex;
	flex-direction: column;
	@media screen and (max-width: 760px) {
		/* flex-direction: row; */
		justify-content: space-between;
	}
`;

const ColumnTitle = styled.p`
	color: #ffffff;
	text-decoration: underline;
	margin: 0 0 10px 0;
	@media screen and (max-width: 760px) {
		text-align: center;
		margin: 0 20px 0 0;
		/* flex: 2; */
		font-size: 14px;
		display: none;
	}
`;

const FooterText = styled.p`
	margin: 0 0 5px 0;
	@media screen and (max-width: 760px) {
		font-size: 16px;
	}
`;

const FooterLink = styled.a`
	color: #fdd803;
	color: #ffffff;
	display: flex;
	gap: 5px;
	text-decoration: none;
	&:hover {
		/* color: #fdd803; */
	}
	@media screen and (max-width: 760px) {
	}
`;

const FooterSection = () => {
	return (
		<FooterSectionContainer>
			<ColumnContainer>
				<Column1>
					<LogoRow>
						<Logo src="./images/logo_round.png" alt="" />
						<BusinessName>Beneficial Projects © {2024}</BusinessName>
					</LogoRow>
					<SocialsRow>
						<SocialIconContainer>
							<FooterLink
								href="https://www.facebook.com/61556584521665/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<SocialIcons src="./images/facebook_icon_white.svg" alt="" />
								Facebook
							</FooterLink>
						</SocialIconContainer>
						<SocialIconContainer>
							<FooterLink
								href="https://www.instagram.com/beneficial.projects_gh/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<SocialIcons src="./images/instagram_icon_white.svg" alt="" /> Instagram
							</FooterLink>
						</SocialIconContainer>
					</SocialsRow>
				</Column1>

				<Column2>
					<ColumnTitle>Organisation</ColumnTitle>
					<FooterText>
						<FooterLink href="#about-charity">About Charity</FooterLink>
					</FooterText>
					<FooterText>
						<FooterLink href="#donate">Donate</FooterLink>
					</FooterText>
				</Column2>
			</ColumnContainer>
		</FooterSectionContainer>
	);
};

export default FooterSection;
