import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const YouTubeVideoContainer = styled.div`
	display: flex;
	justify-content: center;
	padding: 50px 0 0 0;
	background-color: #c1ff72;
`;

const VideoFrame = styled.iframe`
	border-radius: 10px;
	@media screen and (max-width: 960px) {
		border-radius: 0;
	}
`;

const YouTubeVideo = ({ embedId }) => {
	return (
		<YouTubeVideoContainer>
			<VideoFrame
				width="720"
				height="360"
				src={`https://youtube.com/embed/${embedId}`}
				allow="accelerometer; autoplay; clipboard-write; allowFullScreen encrypted-media; gyroscope; picture-in-picture"
				title="Embedded Youtube Video"
			/>
		</YouTubeVideoContainer>
	);
};

YouTubeVideo.prototypes = {
	embedId: PropTypes.string.isRequired,
};

export default YouTubeVideo;
