import React from "react";
import styled from "styled-components";

const AboutCharityContainer = styled.div`
	background-color: #ffffff;

	/* margin: 0 8% 0; */
	padding: 0 8% 0;
`;
const AnchorSpacer = styled.div`
	position: relative;
	top: -80px;
	left: 0;
	height: 1px;
	width: 1px;
	margin: 0px 0 30px; /* margin bottom pushes container into view after link click page scroll */
`;

const ParagraphText = styled.p`
	color: #212529;
	font-size: 20px;
	margin: 5px 0 30px 0;
	@media screen and (max-width: 960px) {
		text-align: center;
	}
`;

const PlansContainer = styled.div`
	margin: 0 auto 50px;
	padding: 20px 20px 0;
	width: 80vw;
	background-color: #f4f4f4;
	border: 1px solid #004aad;
	border-radius: 10px;
	box-shadow: 0px 0px 10px black;
	@media screen and (max-width: 960px) {
		width: 85vw;
	}
	/* Set style property below to activate on mouse hover */
	/* box-shadow: 0px 0px 10px #004aad; */
`;

const HeadingText = styled.h3`
	color: #004aad;
	font-size: 28px;
	margin: 0 0 10px 5px;
	@media screen and (max-width: 960px) {
		font-size: 30px;
		text-align: center;
	}
`;

const ContentRow = styled.div`
	display: flex;
	align-items: center;
	padding: 0 20px 20px 20px;
`;

const ParagraphsContainer = styled.div`
	/* margin: 0 0 0 20px; */
	flex: 10;
`;

const IconImage = styled.img`
	width: 150px;
	flex: 1;
	@media screen and (max-width: 960px) {
		display: none;
	}
`;

const OurServices = () => {
	return (
		<AboutCharityContainer>
			<AnchorSpacer id="about-charity"></AnchorSpacer>
			<ParagraphText>
				In the heart of Ghana, there's a community known as Kokrobite, Tuba and Ngleshie
				Amafrom, its a vibrant under developed community that has many young kids that are eager
				to learn, grow, and thrive. But they are held back by limited access to quality
				education, clean drinking and sanitary waste disposal methods. That's where the
				Beneficial Project comes in - a beacon of hope for a brighter future.
			</ParagraphText>
			<ParagraphText>
				As someone who believes in the power of education, I'm passionate about supporting this
				incredible project and community. By raising funds, we can help provide essential
				resources.
			</ParagraphText>
			<ParagraphText>
				Here's a plan for utilizing donated funds to develop a community in need of good
				education, clean water, and sanitary waste disposal:
			</ParagraphText>
			<div>
				<PlansContainer>
					<HeadingText>I. Education</HeadingText>
					<ContentRow>
						<ParagraphsContainer>
							<p>
								1. Build/renovate schools: Construct new schools or renovate existing ones
								to create a conducive learning environment.
							</p>
							<p>
								2. Hire/train teachers: Employ qualified teachers and provide training to
								enhance their skills.
							</p>
							<p>
								3. Educational resources: Provide textbooks, computers, and other necessary
								resources for students.
							</p>
							<p>
								4. Scholarships: Offer scholarships to deserving students to pursue higher
								education.
							</p>
						</ParagraphsContainer>
						<IconImage src="./images/education_icon.svg" alt="" />
					</ContentRow>
				</PlansContainer>
				<PlansContainer>
					<HeadingText>II. Clean Water (25% of donated funds)</HeadingText>
					<ContentRow>
						<ParagraphsContainer>
							<p>
								1. Water source development: Identify and develop a reliable water source
								(e.g., borehole, well, or water treatment plant).
							</p>
							<p>
								2. Water distribution: Install pipes and infrastructure to distribute clean
								water to households.
							</p>
							<p>
								3. Water storage: Build water storage facilities to ensure a steady supply.
							</p>
							<p>
								4. Water purification: Implement water purification systems to remove
								contaminants.
							</p>
						</ParagraphsContainer>
						<IconImage src="./images/clean_water.svg" alt="" />
					</ContentRow>
				</PlansContainer>
				<PlansContainer>
					<HeadingText>III. Sanitary Waste Disposal (20% of donated funds)</HeadingText>
					<ContentRow>
						<ParagraphsContainer>
							<p>
								1. Build toilets: Construct modern toilets and latrines in schools and
								households.
							</p>
							<p>
								2. Waste management infrastructure: Develop waste collection and disposal
								systems.
							</p>
							<p>
								3. Recycling programs: Implement recycling programs for organic and
								inorganic waste.
							</p>
							<p>
								4. Community education: Educate the community on proper waste disposal and
								hygiene practices.
							</p>
						</ParagraphsContainer>
						<IconImage src="./images/waste_icon.svg" alt="" />
					</ContentRow>
				</PlansContainer>
				<PlansContainer>
					<HeadingText>IV. Community Development (10% of donated funds)</HeadingText>
					<ContentRow>
						<ParagraphsContainer>
							<p>
								1. Community center: Build a community center for social gatherings,
								meetings, sports, skills training and fun activities.
							</p>
							<p>
								2. Healthcare services: Provide basic healthcare services and first aid
								training.
							</p>
							<p>
								3. Economic empowerment: Offer vocational training and microfinance
								initiatives to promote economic growth.
							</p>
						</ParagraphsContainer>
						<IconImage src="./images/parental_child_icon.svg" alt="" />
					</ContentRow>
				</PlansContainer>
				<PlansContainer>
					<HeadingText>V. Administration and Monitoring (15% of donated funds)</HeadingText>
					<ContentRow>
						<ParagraphsContainer>
							<p>
								1. Project management: Employ a project manager to oversee the
								implementation of the plan.
							</p>
							<p>
								2. Monitoring and evaluation: Regularly monitor progress and evaluate the
								impact of the project.
							</p>
							<p>
								3. Community engagement: Foster community involvement and participation in
								the project.
							</p>
						</ParagraphsContainer>
						{/* <IconImage src="./images/education_icon.svg" alt="" /> */}
						<IconImage src="./images/monitoring_icon.svg" alt="" />
					</ContentRow>
				</PlansContainer>
				<ParagraphText>
					This plan allocates funds to address the community's pressing needs while also
					promoting long-term sustainability and development.
				</ParagraphText>
				<ParagraphText>
					Your contribution will directly impact the lives of these talented young individuals,
					giving them the tools they need to succeed and become leaders in their community.
				</ParagraphText>
				<ParagraphText>
					Let's unlock their potential and create a ripple effect of positive change!
				</ParagraphText>
				<ParagraphText>
					Will you join me in supporting the Beneficial Project and empowering the next
					generation of Ghana's leaders?
				</ParagraphText>
				<ParagraphText>
					Every donation counts, and together, we can make a real difference!
				</ParagraphText>
			</div>
		</AboutCharityContainer>
	);
};

export default OurServices;
