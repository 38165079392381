import styled from "styled-components";

const HeroSectionContainer = styled.div`
	color: white;
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
	background: url(./images/hero_image.jpg);
	background-size: cover;
	height: 100vh;

	@media screen and (max-width: 800px) {
		height: 100svh;
		background: url(./images/hero_image_mobile.jpg);
	}
`;

const HeroHeading = styled.h1`
	color: white;
	font-size: 56px;
	padding: 10px 20px;
	margin: 0 auto 20px;
	background-color: hsla(0, 0%, 0%, 0.45);
	border-radius: 10px;
	width: fit-content;
	@media screen and (max-width: 960px) {
		font-size: 36px;
		border-radius: 0;
	}
`;

const Paragraph = styled.p`
	color: white;
	font-size: 28px;
	margin: 20px auto 20px;
	background-color: hsla(0, 0%, 0%, 0.45);
	border-radius: 10px;
	width: fit-content;
	max-width: 850px;
	@media screen and (max-width: 960px) {
		border-radius: 0;
		font-size: 18px;
		padding: 0 20px;
	}
`;

const SocialsContainer = styled.div`
	color: white;
	display: flex;
	height: fit-content;
	padding: 10px 20px;
	margin: 20px auto 0;
	gap: 50px;
	background-color: hsla(0, 0%, 0%, 0.45);
	border-radius: 5px;
	@media screen and (max-width: 800px) {
		height: fit-content;
	}
`;

const SocialIconContainer = styled.div`
	display: flex;
	align-items: center;
`;

const SocialIcons = styled.img`
	width: 20px;
`;

const SocialsLink = styled.a`
	color: #fdd803;
	color: #ffffff;
	display: flex;
	gap: 5px;
	text-decoration: none;
	@media screen and (max-width: 760px) {
	}
`;

const HeroSection = () => {
	return (
		<HeroSectionContainer>
			<HeroHeading>BENEFICIAL PROJECTS</HeroHeading>
			<Paragraph>
				Empowering Young Minds in Ghana: Join the Movement to Transform Lives.
			</Paragraph>
			<SocialsContainer>
				<SocialIconContainer>
					<SocialsLink
						href="https://www.facebook.com/61556584521665/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<SocialIcons src="./images/facebook_icon_white.svg" alt="" />
						Facebook
					</SocialsLink>
				</SocialIconContainer>
				<SocialIconContainer>
					<SocialsLink
						href="https://www.instagram.com/beneficial.projects_gh/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<SocialIcons src="./images/instagram_icon_white.svg" alt="" /> Instagram
					</SocialsLink>
				</SocialIconContainer>
			</SocialsContainer>
		</HeroSectionContainer>
	);
};

export default HeroSection;
