export const Gallerydata = [
	{
		key: 0,
		url: "./images/gallery/03.jpg",
	},
	{
		key: 1,
		url: "./images/gallery/06.jpg",
	},
	{
		key: 2,
		url: "./images/gallery/08.jpg",
	},
	{
		key: 3,
		url: "./images/gallery/17.jpg",
	},
	{
		key: 4,
		url: "./images/gallery/19.jpg",
	},
	{
		key: 5,
		url: "./images/gallery/25.jpg",
	},
	{
		key: 6,
		url: "./images/gallery/29.jpg",
	},
	{
		key: 7,
		url: "./images/gallery/34.jpg",
	},
	{
		key: 8,
		url: "./images/gallery/36.jpg",
	},
	{
		key: 9,
		url: "./images/gallery/37.jpg",
	},
	{
		key: 10,
		url: "./images/gallery/50.jpg",
	},
	{
		key: 11,
		url: "./images/gallery/61.jpg",
	},
	{
		key: 12,
		url: "./images/gallery/54.jpg",
	},
	{
		key: 13,
		url: "./images/gallery/55.jpg",
	},
	{
		key: 14,
		url: "./images/gallery/57.jpg",
	},
	{
		key: 15,
		url: "./images/gallery/58.jpg",
	},
];
